































































































































import Events from '../events'
import { mapActions, mapGetters, mapState } from 'vuex'
import BigNumber from 'bignumber.js'
import { Accessors } from 'vue/types/options'
import Vue from 'vue'
import { toBN, fromWeiEther } from '../utils/common'
import ViewLinks from './ViewLinks.vue'
import SkillBalanceDisplay from './smart/SkillBalanceDisplay.vue'

interface StoreMappedState {
  skillRewards: string
  directStakeBonusPercent: number
}

interface StoreMappedActions {
  claimTokenRewards(): Promise<void>
}
interface Data {
  showGraphics: boolean
  hideRewards: boolean
  hideWalletWarning: boolean
}

interface StoreMappedGetters {
  rewardsClaimTaxAsFactorBN: BigNumber
  maxRewardsClaimTaxAsFactorBN: BigNumber
}

enum ClaimStage {
  WaxBridge = 0,
  Stake = 1,
  Claim = 2,
}

export default Vue.extend({
  components: {
    ViewLinks,
    SkillBalanceDisplay,
  },
  created() {
    this.showGraphics = localStorage.getItem('useGraphics') === 'true'
    this.hideRewards = localStorage.getItem('hideRewards') === 'true'
    this.hideWalletWarning =
      localStorage.getItem('hideWalletWarning') === 'true'
  },

  data() {
    return {
      showGraphics: false,
      hideRewards: false,
      hideWalletWarning: false,
      ClaimStage,
    } as Data
  },

  computed: {
    ...(mapState([
      'skillRewards',
      'directStakeBonusPercent',
    ]) as Accessors<StoreMappedState>),
    ...(mapGetters([
      'rewardsClaimTaxAsFactorBN',
      'maxRewardsClaimTaxAsFactorBN',
    ]) as Accessors<StoreMappedGetters>),

    formattedSkillReward(): string {
      const skillRewards = fromWeiEther(this.skillRewards)
      return `${toBN(skillRewards).toFixed(4)}`
    },
    formattedTaxAmount(): string {
      const skillRewards = fromWeiEther(
        parseFloat(
          String(
            parseFloat(this.skillRewards) *
              parseFloat(String(this.rewardsClaimTaxAsFactorBN))
          )
        ) + ''
      )
      return `${toBN(skillRewards).toFixed(4)}`
    },
    formattedRewardsClaimTax(): string {
      const frac =
        this.skillRewards === '0'
          ? this.maxRewardsClaimTaxAsFactorBN
          : this.rewardsClaimTaxAsFactorBN

      return `${frac
        .multipliedBy(100)
        .decimalPlaces(0, BigNumber.ROUND_HALF_UP)}%`
    },
    formattedBonusLost(): string {
      const skillLost = fromWeiEther(
        parseFloat(
          String(
            (parseFloat(this.skillRewards) * this.directStakeBonusPercent) / 100
          )
        ).toString()
      )
      return `${toBN(skillLost).toFixed(4)}`
    },
    canClaimTokens(): boolean {
      if (toBN(this.skillRewards).lte(0)) {
        return false
      }
      return true
    },
  },

  methods: {
    //@ts-ignore
    ...(mapActions(['claimTokenRewards']) as StoreMappedActions),
    toggleGraphics() {
      this.showGraphics = !this.showGraphics
      if (this.showGraphics) localStorage.setItem('useGraphics', 'true')
      else localStorage.setItem('useGraphics', 'false')

      Events.$emit('setting:useGraphics', { value: this.showGraphics })
    },

    toggleRewards() {
      this.hideRewards = !this.hideRewards
      if (this.hideRewards) localStorage.setItem('hideRewards', 'true')
      else localStorage.setItem('hideRewards', 'false')

      Events.$emit('setting:hideRewards', { value: this.hideRewards })
    },

    async onClaimTokens() {
      if (this.canClaimTokens) {
        await this.claimTokenRewards()
      }
    },
    async claimSkill(stage: ClaimStage) {
      if (stage === ClaimStage.Stake) {
        ;(this.$refs['stake-suggestion-modal'] as any).show()
      }
      if (stage === ClaimStage.Claim) {
        ;(this.$refs['stake-suggestion-modal'] as any).hide()
        ;(this.$refs['claim-confirmation-modal'] as any).show()
      }
    },

    toggleHideWalletWarning() {
      this.hideWalletWarning = !this.hideWalletWarning
      if (this.hideWalletWarning)
        localStorage.setItem('hideWalletWarning', 'true')
      else localStorage.setItem('hideWalletWarning', 'false')

      Events.$emit('setting:hideWalletWarning', {
        value: this.hideWalletWarning,
      })
    },
    hideOption() {
      Events.$emit('hide-option', false)
    },
  },
})

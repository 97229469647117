

























import { mapState } from 'vuex'

export default {
  props: {},

  computed: {
    ...mapState(['defaultAccount']),
  },
  methods: {
    copyReferralLink(defaultAccount: string) {
      const dummy = document.createElement('textarea')
      document.body.appendChild(dummy)
      dummy.value = `https://play.cryptowar.network/?r=${defaultAccount}`
      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        dummy.contentEditable = 'true'
        dummy.readOnly = true
        const range = document.createRange()
        range.selectNodeContents(dummy)
        const selection = window.getSelection()
        selection?.removeAllRanges()
        selection?.addRange(range)
      } else {
        dummy.select()
      }
      document.execCommand('copy')
      document.body.removeChild(dummy)
      alert('Referral link copied to clipboard')
    },
  },
}



























































































































































































































































import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Events from '../../events'
import { Nft as nftItem } from '../../interfaces/Nft'
import { SkillShopListing } from '../../interfaces/SkillShopListing'
import NftIcon from '../NftIcon.vue'
import { Nft } from '@/interfaces/Nft'
import Vue from 'vue'
import { Accessors, PropType } from 'vue/types/options'
import { IState } from '@/interfaces'
import WeaponSelect from '@/components/WeaponSelect.vue'

const sorts = [
  { name: 'Any', dir: '' },
  { name: 'Price: Low -> High', dir: 1 },
  { name: 'Price: High -> Low', dir: -1 },
]

interface Data {
  typeFilter: string
  starFilter: string
  elementFilter: string
  favorites: Record<string, Record<number, boolean>>
  priceSort: string
  showFavoriteNfts: boolean
  checkBuy: string
  lastBoxId: string | number
  isLoadingBox: boolean
  weaponReceive: any
  boxType: string
}

export interface NftIdType {
  id: number | string
  type: string
  image: string
  isSoldOut: boolean
  name: string
  nftPrice: number | string
  supply: number | string
  isDisable: boolean
}

type StoreMappedState = Pick<IState, 'ownedShieldIds'>

interface StoreMappedGetters {
  nftsWithIdType(nftIdType: NftIdType[]): Nft[]
  shieldsWithIds(ids: string[]): Nft[]
}

// interface StoreMappedActions {
//   purchaseShield(): Promise<void>;
//   fetchShields(shieldIds: (string | number)[]): Promise<void>;
//   purchaseRenameTag(): Promise<void>;
//   purchaseRenameTagDeal(): Promise<void>;
//   purchaseWeaponRenameTag(): Promise<void>;
//   purchaseWeaponRenameTagDeal(): Promise<void>;
//   purchaseCharacterFireTraitChange(): Promise<void>;
//   purchaseCharacterEarthTraitChange(): Promise<void>;
//   purchaseCharacterWaterTraitChange(): Promise<void>;
//   purchaseCharacterLightningTraitChange(): Promise<void>;
//   purchaseCommonSecretBox(): Promise<void>;
//   purchaseRareSecretBox(): Promise<void>;
//   purchaseEpicSecretBox(): Promise<void>;
//   openCommonSecretBox(): Promise<void>;
//   openCommonBox(): Promise<void>;
//   fetchWeaponId(weaponId: (string | number)): Promise<any>;
//   buyCommonBoxWithXgem(): Promise<void>;
//   buyRareBoxWithXgem(): Promise<void>;
//   buyEpicBoxWithXgem(): Promise<void>;
// }

export default Vue.extend({
  model: {
    prop: 'highlight',
    event: 'choose-nft',
  },
  props: {
    highlight: {
      // this forces Typescript to consider a prop a certain type
      // without us specifying a "type" property;
      // Vue's "type" property is not as flexible as we need it here
      validator(x: string | number | null) {
        void x
        return true
      },
      default: null,
    },
    showGivenNftIdTypes: {
      type: Boolean,
      default: false,
    },
    nftIdTypes: {
      type: Array as PropType<NftIdType[]>,
      default() {
        return []
      },
    },
    isShop: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isMarket: {
      type: Boolean,
      default: false,
    },
    ignore: {
      // this forces Typescript to consider a prop a certain type
      // without us specifying a "type" property;
      // Vue's "type" property is not as flexible as we need it here
      validator(x: string | number | null) {
        void x
        return true
      },
      default: null,
    },
    showLimit: {
      type: Number,
      default: 0,
    },
    showFavoriteToggle: {
      type: Boolean,
      default: true,
    },
    showFavoriteWeaponsDefVal: {
      type: Boolean,
      default: true,
    },
    canFavorite: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      typeFilter: '',
      starFilter: '',
      elementFilter: '',
      favorites: {},
      priceSort: '',
      sorts,
      showFavoriteNfts: true,
      checkBuy: '',
      lastBoxId: '',
      weaponReceive: null,
      isLoadingBox: false,
      boxType: '',
    } as Data
  },

  components: {
    NftIcon,
    WeaponSelect,
  },

  computed: {
    ...(mapState([
      'ownedShieldIds',
      'skillBalance',
      'inGameOnlyFunds',
      'skillRewards',
      'myXgem',
    ]) as Accessors<StoreMappedState>),
    ...(mapGetters([
      'shieldsWithIds',
      'nftsWithIdType',
    ]) as Accessors<StoreMappedGetters>),

    // nftsToDisplay(): NftIdType[] {
    //   if (this.showGivenNftIdTypes) {
    //     return this.nftIdTypes;
    //   }

    //   const nfts: NftIdType[] = [];
    //   // push different kinds of nfts to nfts array here
    //   this.ownedShieldIds?.forEach(id => { nfts.push({ id, type: 'shield' }); });

    //   return nfts;
    // },

    // displayNfts(): Nft[] {
    //   if(this.isMarket && this.showGivenNftIdTypes) {
    //     const type = this.nftIdTypes && this.nftIdTypes[0]?.type;
    //     switch(type) {
    //     case('shield'):
    //       return this.shieldsWithIds(this.nftsToDisplay.map(x => x.id.toString())).filter(Boolean);
    //     default:
    //       return [];
    //     }
    //   }

    //   return this.nftsWithIdType(this.nftsToDisplay).filter(Boolean);
    // },

    // nonIgnoredNfts(): Nft[] {
    //   let items: Nft[] = [];
    //   this.displayNfts.forEach((x) => items.push(x));

    //   const allIgnore: NftIdType[] = [];
    //   if (!this.showFavoriteNfts) {
    //     for (const type in Object.keys(this.favorites)) {
    //       //@ts-ignore
    //       for(const id in Object.keys(this.favorites[type])) {
    //         allIgnore.push({ type, id });
    //       }
    //     }
    //   }
    //   items = items.filter((x) => allIgnore.findIndex((y) => y.id === x.id && y.type === x.type) < 0);

    //   if(this.typeFilter) {
    //     items = items.filter((x) => x.type?.localeCompare(this.typeFilter, undefined, { sensitivity: 'base' } ) === 0);
    //   }

    //   if (this.starFilter) {
    //     items = items.filter((x) => x.stars === +this.starFilter - 1);
    //   }

    //   if (this.elementFilter) {
    //     items = items.filter((x) => x.element?.includes(this.elementFilter));
    //   }

    //   if (this.showLimit > 0 && items.length > this.showLimit) {
    //     items = items.slice(0, this.showLimit);
    //   }

    //   const favoriteNfts: Nft[] = [];
    //   for (const key in this.favorites) {
    //     const i = items.findIndex((y) => y?.id === +key);
    //     if (i !== -1) {
    //       favoriteNfts.push(items[i]);
    //       items.splice(i, 1);
    //     }
    //   }

    //   return favoriteNfts.concat(items);
    // }
  },

  watch: {
    async nftsToDisplay(newNftsToDisplay: NftIdType[]) {
      const shieldIds: string[] = []
      newNftsToDisplay.forEach((nft) => {
        switch (nft.type) {
          case 'shield':
            shieldIds.push(nft.id.toString())
        }
      })

      if (shieldIds.length > 0) {
        await this.fetchShields(shieldIds)
      }
    },
  },

  methods: {
    //@ts-ignore
    ...mapActions([
      'purchaseShield',
      'fetchShields',
      'purchaseRenameTag',
      'purchaseWeaponRenameTag',
      'purchaseRenameTagDeal',
      'purchaseWeaponRenameTagDeal',
      'purchaseCharacterFireTraitChange',
      'purchaseCharacterEarthTraitChange',
      'purchaseCharacterWaterTraitChange',
      'purchaseCharacterLightningTraitChange',
      'purchaseCommonSecretBox',
      'purchaseRareSecretBox',
      'purchaseEpicSecretBox',
      'openCommonSecretBox',
      'openCommonBox',
      'fetchWeaponId',
      'buyRareBoxWithXGem',
      'buyEpicBoxWithXGem',
      'buyCommonBoxWithXGem',
      'updateMyXgem',
      'convertFragmentToBox',
      'getBoxDetail',
    ]),
    ...mapMutations(['setCurrentNft']),

    async onShieldBuy() {
      await this.purchaseShield()
    },

    saveFilters() {
      if (this.isMarket) {
        sessionStorage.setItem('market-nft-typefilter', this.typeFilter)
        sessionStorage.setItem('market-nft-starfilter', this.starFilter)
        sessionStorage.setItem('market-nft-elementfilter', this.elementFilter)
        sessionStorage.setItem('market-nft-price-order', this.priceSort)
      } else {
        sessionStorage.setItem('nft-typefilter', this.typeFilter)
        sessionStorage.setItem('nft-starfilter', this.starFilter)
        sessionStorage.setItem('nft-elementfilter', this.elementFilter)
      }
      this.$emit('nft-filters-changed')
    },

    clearFilters() {
      if (this.isMarket) {
        sessionStorage.removeItem('market-nft-typefilter')
        sessionStorage.removeItem('market-nft-starfilter')
        sessionStorage.removeItem('market-nft-elementfilter')
        sessionStorage.removeItem('market-nft-price-order')
      } else {
        sessionStorage.removeItem('nft-typefilter')
        sessionStorage.removeItem('nft-starfilter')
        sessionStorage.removeItem('nft-elementfilter')
      }

      this.typeFilter = ''
      this.starFilter = ''
      this.elementFilter = ''
      this.priceSort = ''

      this.$emit('nft-filters-changed')
    },

    toggleFavorite(e: Event, type: string, id: number) {
      e.preventDefault()
      //@ts-ignore
      if (this.favorites[type] && this.favorites[type][id]) {
        //@ts-ignore
        this.$delete(this.favorites[type], id)
      } else {
        //@ts-ignore
        if (!this.favorites[type]) {
          this.$set(this.favorites, type, {})
        } //@ts-ignore
        this.$set(this.favorites[type], id, true)
      }

      localStorage.setItem(
        'favorite-nfts',
        this.getFavoritesString(this.favorites)
      )

      Events.$emit('nft:newFavorite', { type, id })
    },

    onNftClick(type: string, id: number) {
      this.setCurrentNft({ type, id })
      this.$emit('choose-nft', `${type}.${id}`)
    },

    getFavoritesString(
      favorites: Record<string, Record<number, boolean>>
    ): string {
      return JSON.stringify(favorites)
    },

    checkStorageFavorite() {
      const favoritesFromStorage = localStorage.getItem('favorite-nfts')
      if (favoritesFromStorage) {
        this.favorites = JSON.parse(favoritesFromStorage)
      }
    },

    isFavorite(type: string, id: number): boolean {
      //@ts-ignore
      return this.favorites && this.favorites[type] && this.favorites[type][id]
    },

    async buyItemWithXgem(item: nftItem) {
      try {
        this.isLoadingBox = true
        if (item.id === 0) {
          this.boxType = ''
          const response = await this.buyCommonBoxWithXGem()
          //@ts-ignore
          this.lastBoxId = response.boxId
          this.isLoadingBox = false
        } else if (item.id === 1) {
          this.boxType = ''
          const response = await this.buyRareBoxWithXGem()
          //@ts-ignore
          this.lastBoxId = response.boxId
          this.isLoadingBox = false
        } else if (item.id === 2) {
          this.boxType = ''
          const response = await this.buyEpicBoxWithXGem()
          //@ts-ignore
          this.lastBoxId = response.boxId
          this.isLoadingBox = false
        } else {
          const response = await this.convertFragmentToBox()
          if (response) {
            this.lastBoxId = response.boxId
            const boxTypeReturn = await this.getBoxDetail({
              boxId: response.boxId,
            })
            switch (boxTypeReturn) {
              case '1': {
                this.boxType = 'rare'
                break
              }
              case '2': {
                this.boxType = 'epic'
                break
              }
              default: {
                this.boxType = 'common'
                break
              }
            }
          }
          this.isLoadingBox = false
        }
        //@ts-ignore
        this.$bvModal.show('modal-buyitem')
      } catch (error) {
        this.isLoadingBox = false
      }
    },
    async buyItem(item: nftItem) {
      try {
        this.isLoadingBox = true
        this.boxType = ''
        if (item.type === 'shield') {
          console.log('buying shield')
          await this.purchaseShield()
        }

        if (item.type === 'SecretBox') {
          if (item.id === 0) {
            //Common Box
            const boxId = await this.purchaseCommonSecretBox()
            //@ts-ignore
            this.lastBoxId = boxId
            this.isLoadingBox = false
          }
          if (item.id === 1) {
            // Rare Box
            const boxId = await this.purchaseRareSecretBox()
            //@ts-ignore
            this.lastBoxId = boxId
            this.isLoadingBox = false
          }
          if (item.id === 2) {
            // Epic Box
            const boxId = await this.purchaseEpicSecretBox()
            //@ts-ignore
            this.lastBoxId = boxId
            this.isLoadingBox = false
          }
          //@ts-ignore
          this.$bvModal.show('modal-buyitem')
        }

        if (item.type === 'CharacterRenameTag') {
          await this.purchaseRenameTag()
          this.isLoadingBox = false
        }
        if (item.type === 'CharacterRenameTagDeal') {
          await this.purchaseRenameTagDeal()
          this.isLoadingBox = false
        }

        if (item.type === 'WeaponRenameTag') {
          await this.purchaseWeaponRenameTag()
          this.isLoadingBox = false
        }
        if (item.type === 'WeaponRenameTagDeal') {
          await this.purchaseWeaponRenameTagDeal()
          this.isLoadingBox = false
        }

        if (item.type === 'CharacterFireTraitChange') {
          await this.purchaseCharacterFireTraitChange()
          this.isLoadingBox = false
        }
        if (item.type === 'CharacterEarthTraitChange') {
          await this.purchaseCharacterEarthTraitChange()
          this.isLoadingBox = false
        }
        if (item.type === 'CharacterWaterTraitChange') {
          await this.purchaseCharacterWaterTraitChange()
          this.isLoadingBox = false
        }
        if (item.type === 'CharacterLightningTraitChange') {
          await this.purchaseCharacterLightningTraitChange()
          this.isLoadingBox = false
        }
      } catch (error) {
        this.isLoadingBox = false
      }
    },

    async openBox() {
      try {
        this.isLoadingBox = true
        //@ts-ignore
        const res = await this.openCommonBox({ boxId: this.lastBoxId })
        //@ts-ignore
        const weapon = await this.fetchWeaponId(res?.[0]?.returnValues?.tokenId)
        this.weaponReceive = weapon
        this.isLoadingBox = false
        setTimeout(() => {
          //@ts-ignore
          this.$bvModal.show('successOpenBox')
        }, 1000)
      } catch (error) {
        this.isLoadingBox = false
      }
    },

    itemDescriptionHtml(item: SkillShopListing): string {
      return item.name + '<br>' + item.description
    },
  },

  mounted() {
    this.checkStorageFavorite()

    if (!this.showGivenNftIdTypes) {
      this.fetchShields(this.ownedShieldIds)
    }

    Events.$on('nft:newFavorite', () => this.checkStorageFavorite())

    if (this.isMarket) {
      this.typeFilter = sessionStorage.getItem('market-nft-typefilter') || ''
      this.starFilter = sessionStorage.getItem('market-nft-starfilter') || ''
      this.elementFilter =
        sessionStorage.getItem('market-nft-elementfilter') || ''
      this.priceSort = sessionStorage.getItem('market-nft-price-order') || ''
    } else {
      this.typeFilter = sessionStorage.getItem('nft-typefilter') || ''
      this.starFilter = sessionStorage.getItem('nft-starfilter') || ''
      this.elementFilter = sessionStorage.getItem('nft-elementfilter') || ''
    }
  },
})
















































































































































































































































































import Vue from 'vue'
import Events from '../../events'
import { Accessors, PropType } from 'vue/types/options'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { IState, IWeapon } from '../../interfaces'
import WeaponIcon from '../WeaponIcon.vue'
import DustBalanceDisplay from '../smart/DustBalanceDisplay.vue'
// import { mapCacheActions } from 'vuex-cache';

type StoreMappedState = Pick<IState, 'ownedWeaponIds'>

interface StoreMappedGetters {
  weaponsWithIds(weaponIds: (string | number)[]): IWeapon[]
}

interface StoreMappedActions {
  fetchWeapons(weaponIds: string[]): Promise<void>
}

interface Data {
  searchValue: string
  starFilter: string
  elementFilter: string
  minPriceFilter: string
  maxPriceFilter: string
  favorites: Record<number, boolean>
  priceSort: string
  showReforgedWeapons: boolean
  showFavoriteWeapons: boolean
  weaponPaginationPrev: number
  weaponPaginationNext: number
  numberWeaponsShow: number
}

const sorts = [
  { name: 'Any', dir: '' },
  { name: 'Price: Low -> High', dir: 1 },
  { name: 'Price: High -> Low', dir: -1 },
]

export default Vue.extend({
  model: {
    prop: 'highlight',
    event: 'choose-weapon',
  },
  props: {
    highlight: {
      // this forces Typescript to consider a prop a certain type
      // without us specifying a "type" property;
      // Vue's "type" property is not as flexible as we need it here
      validator(x: string | number | null) {
        void x
        return true
      },
      default: null,
    },
    ignore: {
      // this forces Typescript to consider a prop a certain type
      // without us specifying a "type" property;
      // Vue's "type" property is not as flexible as we need it here
      validator(x: string | number | null) {
        void x
        return true
      },
      default: null,
    },
    showGivenWeaponIds: {
      type: Boolean,
      default: false,
    },
    weaponIds: {
      type: Array as PropType<string[]>,
      default() {
        return []
      },
    },
    showLimit: {
      type: Number,
      default: 0,
    },
    showReforgedToggle: {
      type: Boolean,
      default: true,
    },
    showReforgedWeaponsDefVal: {
      type: Boolean,
      default: true,
    },
    showFavoriteToggle: {
      type: Boolean,
      default: true,
    },
    showFavoriteWeaponsDefVal: {
      type: Boolean,
      default: true,
    },
    canFavorite: {
      type: Boolean,
      default: true,
    },
    isBlacksmith: {
      type: Boolean,
      default: false,
    },
    isBurnWeapon: {
      type: Boolean,
      default: false,
    },
    isMarket: {
      type: Boolean,
      default: false,
    },
    checkForDurability: {
      type: Boolean,
      default: false,
    },
    newWeapon: {
      type: Boolean,
      default: false,
    },
    isSell: {
      type: Boolean,
      default: false,
    },
    sellClick: {
      type: () => {
        // Ignore
      },
      default: null,
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
    cancelNftListing: {
      type: () => {
        // Ignore
      },
      default: null,
    },
    isBtnSell: {
      type: Boolean,
      default: false,
    },
    showListingSetupModal: {
      type: () => {
        // Ignore
      },
      default: null,
    },
    isPage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchValue: '',
      starFilter: '',
      elementFilter: '',
      minPriceFilter: '',
      maxPriceFilter: '',
      favorites: {},
      priceSort: '',
      sorts,
      showReforgedWeapons: this.showReforgedWeaponsDefVal,
      showFavoriteWeapons: this.showFavoriteWeaponsDefVal,
      weaponPaginationPrev: -1,
      weaponPaginationNext: 6,
      numberWeaponsShow: 6,
      windowWidth: window.innerWidth,
    } as Data
  },

  components: {
    WeaponIcon,
    DustBalanceDisplay,
  },

  computed: {
    ...(mapState(['ownedWeaponIds']) as Accessors<StoreMappedState>),
    ...(mapGetters([
      'weaponsWithIds',
      'getWeaponDurability',
    ]) as Accessors<StoreMappedGetters>),

    weaponIdsToDisplay(): string[] {
      if (this.showGivenWeaponIds) {
        return this.weaponIds
      }

      return this.ownedWeaponIds?.map((id) => id.toString())
    },

    displayWeapons(): IWeapon[] {
      return this.weaponsWithIds(this.weaponIdsToDisplay).filter(Boolean)
    },

    nonIgnoredWeapons(): IWeapon[] {
      if (this.newWeapon) {
        return this.displayWeapons
      }

      let items: IWeapon[] = []
      this.displayWeapons.forEach((x) => items.push(x))

      const allIgnore: string[] = []
      if (this.ignore) {
        //@ts-ignore
        allIgnore.push((this.ignore || '').toString())
      }
      if (!this.showFavoriteWeapons) {
        for (const key in this.favorites) {
          allIgnore.push(key)
        }
      }
      items = items.filter(
        (x) => allIgnore.findIndex((y) => y === x.id.toString()) < 0
      )

      if (this.searchValue !== '' && !this.isMarket) {
        items = items.filter((x) => x.id === parseInt(this.searchValue, 10))
      }

      if (this.starFilter && !this.isMarket) {
        items = items.filter((x) => x.stars === +this.starFilter - 1)
      }

      if (this.elementFilter && !this.isMarket) {
        items = items.filter((x) => x.element.includes(this.elementFilter))
      }

      if (!this.showReforgedWeapons) {
        items = items.filter((x) => x.bonusPower === 0)
      }

      if (this.showLimit > 0 && items.length > this.showLimit) {
        items = items.slice(0, this.showLimit)
      }

      const favoriteWeapons: IWeapon[] = []
      for (const key in this.favorites) {
        const i = items.findIndex((y) => y.id === +key)
        if (i !== -1) {
          favoriteWeapons.push(items[i])
          items.splice(i, 1)
        }
      }

      return favoriteWeapons.concat(items)
    },
  },

  watch: {
    async weaponIdsToDisplay(newWeaponIds: string[]) {
      await this.fetchWeapons(newWeaponIds)
    },
    windowWidth(newWidth) {
      if (newWidth < 1700) {
        this.weaponPaginationNext = 4
        this.numberWeaponsShow = 4
      } else {
        this.weaponPaginationNext = 6
        this.numberWeaponsShow = 6
      }
    },
  },

  methods: {
    ...(mapActions(['fetchWeapons']) as StoreMappedActions),
    ...mapMutations(['setCurrentWeapon']),

    setFilterOnMobileState(filterState: boolean) {
      this.$el
        .getElementsByClassName('filters')[0]
        .classList.toggle('active', filterState)
    },

    saveFilters() {
      if (this.isMarket) {
        sessionStorage.setItem('market-weapon-starfilter', this.starFilter)
        sessionStorage.setItem(
          'market-weapon-elementfilter',
          this.elementFilter
        )
        sessionStorage.setItem('market-weapon-price-order', this.priceSort)
        sessionStorage.setItem(
          'market-weapon-price-minfilter',
          this.minPriceFilter ? '' + this.minPriceFilter : ''
        )
        sessionStorage.setItem(
          'market-weapon-price-maxfilter',
          this.maxPriceFilter ? '' + this.maxPriceFilter : ''
        )
        sessionStorage.setItem('market-weapon-searchvalue', this.searchValue)
      } else {
        sessionStorage.setItem('weapon-starfilter', this.starFilter)
        sessionStorage.setItem('weapon-elementfilter', this.elementFilter)
      }
      this.$emit('weapon-filters-changed')
    },

    toggleFavorite(e: Event, weaponId: number) {
      e.preventDefault()
      if (this.favorites[weaponId]) {
        this.$delete(this.favorites, weaponId)
      } else {
        this.$set(this.favorites, weaponId, true)
      }

      localStorage.setItem('favorites', this.getFavoritesString(this.favorites))

      Events.$emit('weapon:newFavorite', { value: weaponId })
    },

    getFavoritesString(favorites: Record<number, boolean>): string {
      return JSON.stringify(favorites)
    },

    getFavoritesMap(favorites: string): Record<number, boolean> {
      if (!favorites) {
        return {}
      }

      const favoritesMap: Record<number, boolean> = {}
      favorites.split(',').forEach((x) => (favoritesMap[+x] = true))
      return favoritesMap
    },

    isFavorite(weaponId: number): boolean {
      return this.favorites[weaponId]
    },

    clearFilters() {
      if (this.isMarket) {
        sessionStorage.removeItem('market-weapon-starfilter')
        sessionStorage.removeItem('market-weapon-elementfilter')
        sessionStorage.removeItem('market-weapon-price-order')
        sessionStorage.removeItem('market-weapon-price-minfilter')
        sessionStorage.removeItem('market-weapon-price-maxfilter')
        sessionStorage.removeItem('market-weapon-searchvalue')
      } else {
        sessionStorage.removeItem('weapon-starfilter')
        sessionStorage.removeItem('weapon-elementfilter')
      }
      this.elementFilter = ''
      this.starFilter = ''
      this.priceSort = ''
      this.minPriceFilter = ''
      this.maxPriceFilter = ''

      this.$emit('weapon-filters-changed')
    },

    onWeaponClick(id: number) {
      this.setCurrentWeapon(id)
      this.$emit('chooseweapon', id)
      this.$emit('choose-weapon', id)
    },

    checkStorageFavorite() {
      const favoritesFromStorage = localStorage.getItem('favorites')
      if (favoritesFromStorage) {
        this.favorites = JSON.parse(favoritesFromStorage)
      }
    },

    prevWeaponList() {
      if (this.weaponPaginationPrev !== -1) {
        this.weaponPaginationPrev -= this.numberWeaponsShow
        this.weaponPaginationNext -= this.numberWeaponsShow
        this.$el
          .querySelector('.weapon-pagination-next')
          ?.classList.remove('disabled')
      }
      this.$el
        .querySelector('.weapon-pagination-prev')
        ?.classList.toggle('disabled', this.weaponPaginationPrev === -1)
    },

    nextWeaponList() {
      if (this.weaponPaginationNext <= this.nonIgnoredWeapons.length) {
        this.weaponPaginationPrev += this.numberWeaponsShow
        this.weaponPaginationNext += this.numberWeaponsShow
        this.$el
          .querySelector('.weapon-pagination-prev')
          ?.classList.remove('disabled')
      }
      this.$el
        .querySelector('.weapon-pagination-next')
        ?.classList.toggle(
          'disabled',
          this.weaponPaginationNext > this.nonIgnoredWeapons.length
        )
    },

    onResize() {
      ;(this as any).windowWidth = window.innerWidth
    },
  },

  created() {
    this.clearFilters()
  },

  mounted() {
    this.checkStorageFavorite()

    Events.$on('weapon:newFavorite', () => this.checkStorageFavorite())

    if (screen.width < 1700) {
      this.weaponPaginationNext = 4
      this.numberWeaponsShow = 4
    } else {
      this.weaponPaginationNext = 6
      this.numberWeaponsShow = 6
    }

    if (this.isMarket) {
      this.starFilter = sessionStorage.getItem('market-weapon-starfilter') || ''
      this.elementFilter =
        sessionStorage.getItem('market-weapon-elementfilter') || ''
      this.priceSort = sessionStorage.getItem('market-weapon-price-order') || ''
      this.minPriceFilter =
        sessionStorage.getItem('market-weapon-price-minfilter') || ''
      this.maxPriceFilter =
        sessionStorage.getItem('market-weapon-price-maxfilter') || ''
    } else {
      this.starFilter = sessionStorage.getItem('weapon-starfilter') || ''
      this.elementFilter = sessionStorage.getItem('weapon-elementfilter') || ''
    }

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
})

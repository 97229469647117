






























































































































































































import BN from 'bignumber.js'
import BigButton from '../components/BigButton.vue'
import CharacterList from '../components/smart/CharacterList.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { fromWeiEther, toBN } from '../utils/common'
import { BModal, BvModalEvent } from 'bootstrap-vue'
import Vue from 'vue'
import { getCleanName, isProfaneIsh } from '../rename-censor'
import { mapCacheActions } from 'vuex-cache'

let getConsumablesCountInterval: any = null

interface Data {
  recruitCost: string
  haveRename: number
  characterRename: string
  haveChangeTraitFire: number
  haveChangeTraitEarth: number
  haveChangeTraitWater: number
  haveChangeTraitLightning: number
  targetTrait: string
  heroAmount: number
}

export default Vue.extend({
  computed: {
    ...mapState([
      'characters',
      'maxStamina',
      'currentCharacterId',
      'defaultAccount',
      'skillBalance',
    ]),
    ...mapGetters([
      'contracts',
      'ownCharacters',
      'ownWeapons',
      'currentCharacter',
      'currentCharacterStamina',
      'getCharacterName',
      'getExchangeUrl',
    ]),

    character(): any {
      if (!this.currentCharacter) {
        return {
          id: null,
          name: '???',
          level: -1,
          experience: -1,
        }
      }

      const c = this.currentCharacter
      return {
        id: c.id,
        name: this.getCharacterName(c.id),
        level: c.level,
        experience: c.xp,
      }
    },

    availableTraits(): string[] {
      const availableTraits = []
      if (this.haveChangeTraitFire > 0) {
        availableTraits.push('Fire')
      }
      if (this.haveChangeTraitEarth > 0) {
        availableTraits.push('Earth')
      }
      if (this.haveChangeTraitWater > 0) {
        availableTraits.push('Water')
      }
      if (this.haveChangeTraitLightning > 0) {
        availableTraits.push('Lightning')
      }

      return availableTraits
    },

    isRenameProfanish(): boolean {
      return isProfaneIsh(this.characterRename)
    },

    cleanRename(): string {
      return getCleanName(this.characterRename)
    },
    referralAddress(): string {
      // @ts-ignore
      const referralAddress = this.$route.query.r
      if (referralAddress && referralAddress !== this.defaultAccount) {
        return referralAddress
      }
      return '0x0000000000000000000000000000000000000000'
    },
  },

  async created() {
    const recruitCost = await this.contracts.CWController.methods
      .getMintPriceByToken()
      .call({ from: this.defaultAccount })
    this.recruitCost = new BN(recruitCost).div(new BN(10).pow(18)).toFixed(2)
    this.loadConsumablesCount()
    getConsumablesCountInterval = setInterval(async () => {
      this.loadConsumablesCount()
    }, 3000)

    const heroAmount = await this.contracts.Characters.methods
      .availableAmount()
      .call({ from: this.defaultAccount })

    this.heroAmount = Number(heroAmount)
  },

  destroyed() {
    clearInterval(getConsumablesCountInterval)
  },

  data() {
    return {
      recruitCost: '0',
      haveRename: 0,
      characterRename: '',
      haveChangeTraitFire: 0,
      haveChangeTraitEarth: 0,
      haveChangeTraitWater: 0,
      haveChangeTraitLightning: 0,
      targetTrait: '',
      heroAmount: 0,
    } as Data
  },

  methods: {
    ...mapMutations(['setCurrentCharacter']),
    ...mapActions([
      'mintCharacter',
      'renameCharacter',
      'changeCharacterTraitLightning',
      'changeCharacterTraitEarth',
      'changeCharacterTraitFire',
      'changeCharacterTraitWater',
    ]),
    ...mapCacheActions([
      'fetchTotalRenameTags',
      'fetchTotalCharacterFireTraitChanges',
      'fetchTotalCharacterEarthTraitChanges',
      'fetchTotalCharacterWaterTraitChanges',
      'fetchTotalCharacterLightningTraitChanges',
    ]),

    async onMintCharacter() {
      // await this.mintCharacter(this.referralAddress ? this.referralAddress : '0x0000000000000000000000000000000000000000');
      try {
        await this.mintCharacter(
          this.referralAddress
            ? this.referralAddress
            : '0x0000000000000000000000000000000000000000'
        )
      } catch (e) {
        ;(this as any).$dialog.notify.error(
          'Could not mint character: insufficient funds or transaction denied.'
        )
      }
    },

    async onMintCharaterWithBNB() {
      try {
        await this.onMintCharaterWithBNB()
      } catch (e) {
        ;(this as any).$dialog.notify.error(
          'Could not mint character: insufficient funds or transaction denied.'
        )
      }
    },

    formatSkill() {
      return fromWeiEther(this.skillBalance)
    },
    canRecruit() {
      const cost =
        toBN(this.recruitCost)
          .div(10 ** 9)
          .toNumber() /
        10 ** 9
      const balance = toBN(this.skillBalance)
      return balance.isGreaterThanOrEqualTo(cost)
    },
    canRename() {
      return (
        this.haveRename > 0 &&
        this.currentCharacter !== undefined &&
        this.currentCharacter.id >= 0
      )
    },
    openRenameCharacter() {
      ;(this.$refs['character-rename-modal'] as BModal).show()
    },
    async renameCharacterCall(bvModalEvt: BvModalEvent) {
      if (this.characterRename.length < 2 || this.characterRename.length > 24) {
        bvModalEvt.preventDefault()
        return
      }

      await this.renameCharacter({
        id: this.currentCharacter.id,
        name: this.characterRename.trim(),
      })
      //@ts-ignore
      this.haveRename = await this.fetchTotalRenameTags()
    },

    canChangeTrait() {
      return (
        (this.haveChangeTraitFire > 0 ||
          this.haveChangeTraitEarth > 0 ||
          this.haveChangeTraitWater > 0 ||
          this.haveChangeTraitLightning > 0) &&
        this.currentCharacter !== undefined &&
        this.currentCharacter.id >= 0
      )
    },
    openChangeTrait() {
      ;(this.$refs['character-change-trait-modal'] as BModal).show()
    },
    async changeCharacterTraitCall(bvModalEvt: BvModalEvent) {
      if (!this.targetTrait) {
        bvModalEvt.preventDefault()
      }
      switch (this.targetTrait) {
        case 'Fire':
          await this.changeCharacterTraitFire({ id: this.currentCharacter.id })
          this.haveChangeTraitFire =
            //@ts-ignore
            await this.fetchTotalCharacterFireTraitChanges()
          break
        case 'Earth':
          await this.changeCharacterTraitEarth({
            id: this.currentCharacter.id,
          })
          this.haveChangeTraitEarth =
            //@ts-ignore
            await this.fetchTotalCharacterEarthTraitChanges()
          break
        case 'Water':
          await this.changeCharacterTraitWater({
            id: this.currentCharacter.id,
          })
          this.haveChangeTraitWater =
            //@ts-ignore
            await this.fetchTotalCharacterWaterTraitChanges()
          break
        case 'Lightning':
          await this.changeCharacterTraitLightning({
            id: this.currentCharacter.id,
          })
          this.haveChangeTraitLightning =
            //@ts-ignore
            await this.fetchTotalCharacterLightningTraitChanges()
          break
      }
    },

    async loadConsumablesCount() {
      //@ts-ignore
      this.haveRename = await this.fetchTotalRenameTags() // the other type of call returned 0 on testnet but not on local
      this.haveChangeTraitFire =
        //@ts-ignore
        await this.fetchTotalCharacterFireTraitChanges()
      this.haveChangeTraitEarth =
        //@ts-ignore
        await this.fetchTotalCharacterEarthTraitChanges()
      this.haveChangeTraitWater =
        //@ts-ignore
        await this.fetchTotalCharacterWaterTraitChanges()
      this.haveChangeTraitLightning =
        //@ts-ignore
        await this.fetchTotalCharacterLightningTraitChanges()
    },
  },

  components: {
    BigButton,
    CharacterList,
  },
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"character-display-container",on:{"mouseover":function($event){_vm.hover = !_vm.isMobile() || true},"mouseleave":function($event){_vm.hover = !_vm.isMobile()}}},[_c('transition',{attrs:{"name":"slide-fade"}}),_c('div',{staticClass:"character-full-list"},[_c('ul',{staticClass:"character-list",class:[
        _vm.getIsInCombat ? 'disabled-li' : '',
        _vm.getIsCharacterViewExpanded ? '' : 'centered-list' ]},_vm._l((_vm.filteredCharactersForList),function(c){return _c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: ("Power: " + (_vm.CharacterPower(c.level).toLocaleString()) + "<br>\n        <span>Level </span>\n        <span\n          >" + (c.level + 1) + " (" + (c.xp) + " /\n          " + (_vm.RequiredXp(c.level).toLocaleString()) + " XP)\n        </span>"),
          trigger: _vm.isMobile() ? 'click' : 'hover',
        }),expression:"{\n          content: `Power: ${CharacterPower(c.level).toLocaleString()}<br>\n        <span>Level </span>\n        <span\n          >${c.level + 1} (${c.xp} /\n          ${RequiredXp(c.level).toLocaleString()} XP)\n        </span>`,\n          trigger: isMobile() ? 'click' : 'hover',\n        }"}],key:c.id,class:("" + (_vm.setListClassForSelChar(c.id, _vm.currentCharacterId))),style:(("--staminaReady: " + ((_vm.getCharacterStamina(c.id) / _vm.maxStamina) * 100) + "%;")),on:{"click":function($event){!_vm.getIsInCombat && _vm.setCurrentCharacter(c.id) && _vm.alert(c.id),
            _vm.setCountTargetToFight()}}},[_c('div',{staticClass:"element-icon"},[_c('span',{class:_vm.traits[c.trait].toLowerCase() + '-icon trait-icon'})]),_c('div',[_c('div',{staticClass:"name-list"},[_c('div',[_vm._v(_vm._s(_vm.getCleanCharacterName(c.id)))]),_vm._v(" Lv."+_vm._s(c.level + 1)+" ")]),(_vm.getCharacterStamina(c.id) === _vm.maxStamina)?_c('div',{staticClass:"small-stamina-char",style:(("--staminaReady: " + ((_vm.getCharacterStamina(c.id) / _vm.maxStamina) * 100) + "%;"))},[_c('div',{staticClass:"stamina-text"},[_vm._v(" STA "+_vm._s(_vm.getCharacterStamina(c.id))+" / 200 ")])]):_vm._e(),(_vm.getCharacterStamina(c.id) !== _vm.maxStamina)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
              content: _vm.toolTipHtml(
                _vm.timeUntilCharacterHasMaxStamina(c.id),
                _vm.getTimeStamina(c.id, c.level + 1)
              ),
              trigger: _vm.isMobile() ? 'click' : 'hover',
            }),expression:"{\n              content: toolTipHtml(\n                timeUntilCharacterHasMaxStamina(c.id),\n                getTimeStamina(c.id, c.level + 1)\n              ),\n              trigger: isMobile() ? 'click' : 'hover',\n            }",modifiers:{"bottom":true}}],staticClass:"small-stamina-char",style:(("--staminaReady: " + ((_vm.getCharacterStamina(c.id) / _vm.maxStamina) * 100) + "%;"))},[_c('div',{staticClass:"stamina-text"},[_vm._v(" STA "+_vm._s(_vm.getCharacterStamina(c.id))+" / 200 ")])]):_vm._e()])])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
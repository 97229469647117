var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body main-font"},[(_vm.ownCharacters.length > 0)?_c('character-bar',{attrs:{"setCountTargetToFight":_vm.setCountTargetToFight}}):_vm._e(),_c('div',{staticClass:"nav-bottom-line"}),(_vm.ownWeapons.length > 0 && _vm.ownCharacters.length > 0)?_c('div',[(_vm.error !== null)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col error"},[_vm._v("Error: "+_vm._s(_vm.error))])]):_vm._e(),_c('b-modal',{attrs:{"id":"fightResultsModal","hide-footer":"","centered":""}},[_c('link',{attrs:{"rel":"prefetch","as":"image","href":"/img/Congrats.5ac13bd5.gif"}}),_c('img',{staticClass:"background-win",attrs:{"src":require("../assets/v2/Congrats.gif")}}),_c('div',{staticClass:"title-results"},[_vm._v("Fight Results")]),(_vm.resultsAvailable)?_c('CombatResults',{attrs:{"results":_vm.fightResults}}):_vm._e(),_c('button',{staticClass:"mt-3 btn-buy btn-close-fight-results",attrs:{"block":""},on:{"click":function($event){_vm.$bvModal.hide('fightResultsModal'), _vm.pauseSound()}}},[_vm._v(" Close ")])],1),_c('b-modal',{attrs:{"id":"weaponDurabilityWarning","hide-footer":"","title":"Not enough durability"}},[_c('div',[_vm._v("Not enough durability, choose others weapon")]),_c('b-button',{staticClass:"mt-3 btn-buy",attrs:{"block":""},on:{"click":function($event){return _vm.$bvModal.hide('weaponDurabilityWarning')}}},[_vm._v("Close")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(!_vm.currentCharacter)?_c('div',{staticClass:"message-box"},[_vm._v(" You need to select a character to do battle. ")]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center out-stamina"},[(
                _vm.currentCharacter && _vm.currentCharacterStamina < _vm.staminaPerFight
              )?_c('div',{staticClass:"message-box flex-column"},[(
                  _vm.selectedWeaponId && !_vm.weaponHasDurability(_vm.selectedWeaponId)
                )?_c('div',{staticClass:"message-box message-weapon-durability"},[_vm._v(" This weapon does not have enough durability. ")]):_vm._e(),_vm._v(" You need "+_vm._s(_vm.staminaPerFight)+" stamina to do battle. "),_c('h4',[_vm._v("Stamina Cost Per Fight")]),_c('b-form-select',{staticClass:"ml-3",attrs:{"options":_vm.setStaminaSelectorValues()},on:{"change":function($event){return _vm.setFightMultiplier()}},model:{value:(_vm.fightMultiplier),callback:function ($$v) {_vm.fightMultiplier=$$v},expression:"fightMultiplier"}})],1):_vm._e()])]),(
            _vm.selectedWeaponId &&
            !_vm.weaponHasDurability(_vm.selectedWeaponId) &&
            _vm.currentCharacter &&
            _vm.currentCharacterStamina >= _vm.staminaPerFight
          )?_c('div',{staticClass:"message-box"},[_vm._v(" This weapon does not have enough durability. ")]):_vm._e(),(_vm.timeMinutes === 59 && _vm.timeSeconds >= 30)?_c('div',{staticClass:"message-box"},[_vm._v(" You cannot do battle during the last 30 seconds of the hour. Stand fast! ")]):_vm._e()])]),(_vm.currentCharacterStamina >= _vm.staminaPerFight)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.showModalFight)?_c('div',{attrs:{"id":"fight-overlay"}},[(_vm.waitingResults)?_c('div',{staticClass:"waiting animation",attrs:{"margin":"auto"}},[_c('div',{staticClass:"fighting-img"})]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"combat-enemy-container"},[_c('div',{staticClass:"col weapon-selection"},[_c('div',{staticClass:"header-row"},[_c('div',{staticClass:"info-weapon"},[_c('div',{class:[
                    'col-12',
                    _vm.selectedWeaponId
                      ? 'col-md-6 offset-md-3'
                      : 'col-md-2 offset-md-5' ]},[_c('h4',[_vm._v("Stamina Cost per Fight")]),_c('div',[_c('b-form-select',{attrs:{"id":"select-stamina","options":_vm.setStaminaSelectorValues()},on:{"change":function($event){return _vm.setFightMultiplier()}},model:{value:(_vm.fightMultiplier),callback:function ($$v) {_vm.fightMultiplier=$$v},expression:"fightMultiplier"}}),_c('label',{staticClass:"dropdown-icon",attrs:{"for":"select-stamina"}})],1)])]),(_vm.selectedWeaponId)?_c('div',{staticClass:"weapon-icon-wrapper combat"},[_c('weapon-icon',{staticClass:"weapon-icon",attrs:{"weapon":_vm.selectedWeapon}})],1):_vm._e(),(_vm.selectedWeaponId)?_c('b-button',{staticClass:"ml-3 choose_weapon",attrs:{"id":"gtag-link-others","tagname":"choose_weapon"},on:{"click":function($event){_vm.selectedWeaponId = null}}},[_vm._v(" + Choose New Weapon ")]):_vm._e()],1),(!_vm.selectedWeaponId)?_c('weapon-grid',{attrs:{"checkForDurability":true,"showFilters":""},model:{value:(_vm.selectedWeaponId),callback:function ($$v) {_vm.selectedWeaponId=$$v},expression:"selectedWeaponId"}}):_vm._e()],1),(_vm.targets.length > 0)?_c('div',{staticClass:"nav-line boder"}):_vm._e(),(_vm.targets.length > 0)?_c('div',{staticClass:"row mb-3 enemy-container"},[_vm._m(0),_c('div',{staticClass:"row list-enemy"},_vm._l((_vm.targets),function(e,i){return _c('div',{key:i,staticClass:"encounter"},[_c('div',{staticClass:"encounter-container"},[_c('div',{staticClass:"enemy-character"},[_c('div',{staticClass:"encounter-element"},[_c('span',{class:_vm.getCharacterTrait(e.trait).toLowerCase() +
                          '-icon element-icon circle-element'})]),_c('div',{staticClass:"enemy-id"},[_vm._v("ID "+_vm._s(i))]),_c('div',{},[_c('img',{staticClass:"mx-auto enemy-img",attrs:{"src":_vm.getEnemyArt(e.power),"alt":"Enemy"}}),_c('img',{staticClass:"enemy-around-img",attrs:{"src":_vm.getEnemyArtAround(e.trait),"alt":"Enemy Around"}})]),_c('div',{staticClass:"encounter-power"},[_vm._v(_vm._s(e.power)+" Power")]),_c('div',{staticClass:"xp-gain"},[_vm._v("+"+_vm._s(_vm.getPotentialXp(e))+" XP")])]),_c('div',{staticClass:"victory-chance"},[_vm._v(" "+_vm._s(_vm.getWinChance(e.power, e.trait))+" Victory ")]),_c('big-button',{staticClass:"encounter-button btn-styled",staticStyle:{"margin":"0 auto"},attrs:{"mainText":"FIGHT","disabled":(_vm.timeMinutes === 59 && _vm.timeSeconds >= 30) ||
                      _vm.waitingResults ||
                      !_vm.weaponHasDurability(_vm.selectedWeaponId) ||
                      !_vm.charHasStamina()},on:{"click":function($event){_vm.onClickEncounter(e), (_vm.showModalFight = true)}}}),(_vm.isLoadingTargets)?_c('p',[_vm._v("Loading...")]):_vm._e()],1)])}),0)]):_vm._e()])])]):_vm._e(),_c('div')],1):_vm._e(),(_vm.ownWeapons.length === 0 || _vm.ownCharacters.length === 0)?_c('div',{staticClass:"blank-slate"},[(_vm.ownWeapons.length === 0)?_c('div',[_vm._v(" You do not currently have any weapons. You can buy one at the CryptoWars shop. ")]):_vm._e(),(_vm.ownCharacters.length === 0)?_c('div',[_vm._v(" You do not currently have any characters. You can recruit one at the Inventory. ")]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 text-center"},[_c('div',{staticClass:"combat-hints"},[_c('div',{staticClass:"property-icon"})])])}]

export { render, staticRenderFns }
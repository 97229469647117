var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"weapon-icon"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"containerTop"},[_c('div',{staticClass:"containerColumn"},[_c('div',{staticClass:"containerWeaponStar"},[_c('span',{class:_vm.weapon.element.toLowerCase() + '-icon'}),_vm._l((this.weapon.stars + 1),function(n){return _c('span',{key:n,staticClass:"weapon-star"},[_c('i',{staticClass:"fas fa-star"})])})],2),_c('div',{staticClass:"stats"},[(_vm.weapon.stat1Value)?_c('div',{staticClass:"wrapStat"},[_c('span',{staticClass:"mr-1 icon",class:_vm.weapon.stat1.toLowerCase() + '-icon'}),_c('span',{class:_vm.weapon.stat1.toLowerCase()},[_vm._v(_vm._s(_vm.weapon.stat1)+" +"+_vm._s(_vm.weapon.stat1Value))])]):_vm._e(),(_vm.weapon.stat2Value)?_c('div',[_c('span',{staticClass:"mr-1 icon",class:_vm.weapon.stat2.toLowerCase() + '-icon'}),_c('span',{class:_vm.weapon.stat2.toLowerCase()},[_vm._v(_vm._s(_vm.weapon.stat2)+" +"+_vm._s(_vm.weapon.stat2Value))])]):_vm._e(),(_vm.weapon.stat3Value)?_c('div',[_c('span',{staticClass:"mr-1 icon",class:_vm.weapon.stat3.toLowerCase() + '-icon'}),_c('span',{class:_vm.weapon.stat3.toLowerCase()},[_vm._v(_vm._s(_vm.weapon.stat3)+" +"+_vm._s(_vm.weapon.stat3Value))])]):_vm._e()])]),_c('span',{staticClass:"idWeapon"},[_vm._v("ID "+_vm._s(this.weapon.id))])])]),_c('div',[(_vm.showPlaceholder)?_c('img',{staticClass:"placeholder",attrs:{"src":_vm.getWeaponArt(_vm.weapon)}}):_vm._e()]),_c('div',{staticClass:"name"},[_c('div',[_vm._v(_vm._s(_vm.getCleanWeaponName(_vm.weapon.id, _vm.weapon.stars)))])]),(_vm.getWeaponDurability(_vm.weapon.id))?_c('div',[(_vm.getWeaponDurability(_vm.weapon.id) === _vm.maxDurability)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
        content: ("Durability: " + (_vm.getWeaponDurability(
          _vm.weapon.id
        )) + "/" + _vm.maxDurability + "<br>"),
        trigger: _vm.isMobile() ? 'click' : 'hover',
      }),expression:"{\n        content: `Durability: ${getWeaponDurability(\n          weapon.id\n        )}/${maxDurability}<br>`,\n        trigger: isMobile() ? 'click' : 'hover',\n      }",modifiers:{"bottom":true}}],staticClass:"small-durability-bar",style:(("--durabilityReady: " + ((_vm.getWeaponDurability(_vm.weapon.id) / _vm.maxDurability) * 100) + "%;"))}):_vm._e(),(_vm.getWeaponDurability(this.weapon.id) !== _vm.maxDurability)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(
        ("Durability: " + (_vm.getWeaponDurability(_vm.weapon.id)) + "/" + _vm.maxDurability + "<br>\n        Repairs 1 point every 50 minutes")
      ),expression:"\n        `Durability: ${getWeaponDurability(weapon.id)}/${maxDurability}<br>\n        Repairs 1 point every 50 minutes`\n      ",modifiers:{"bottom":true}}],staticClass:"small-durability-bar",style:(("--durabilityReady: " + ((_vm.getWeaponDurability(_vm.weapon.id) / _vm.maxDurability) * 100) + "%;"))}):_vm._e()]):_vm._e(),(!_vm.getWeaponDurability(_vm.weapon.id))?_c('div',[_c('div',{staticClass:"small-durability-bar",style:(("--durabilityReady: " + ((_vm.getWeaponDurability(_vm.weapon.id) / _vm.maxDurability) * 100) + "%;"))})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body main-font home"},[(_vm.ownCharacters.length === 0)?_c('div',{staticClass:"blank-slate"},[(_vm.heroAmount > 0)?_c('div',{staticClass:"current-promotion promotion-hero-left"},[_c('strong',{staticClass:"upper-text"},[_c('strong',{staticClass:"upper-text promotion-number"},[_vm._v(_vm._s(_vm.heroAmount))]),_vm._v(" heroes left!")])]):_vm._e(),(_vm.heroAmount === 0)?_c('div',{staticClass:"current-promotion promotion-hero-left"},[_c('strong',{staticClass:"upper-text"},[_vm._v("No more heroes left! Please buy in Market")])]):_vm._e(),_c('div',{staticClass:"current-promotion"},[_c('strong',{staticClass:"upper-text"},[_vm._v("Mint Hero NFT "),(
            _vm.referralAddress == '0x0000000000000000000000000000000000000000'
          )?_c('span',{staticClass:"price"},[_vm._v(_vm._s(_vm.recruitCost))]):_vm._e(),(
            _vm.referralAddress != '0x0000000000000000000000000000000000000000'
          )?_c('span',{staticClass:"price"},[_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(_vm.recruitCost))]),_vm._v(" "+_vm._s((_vm.recruitCost * 0.93).toFixed(2)))]):_vm._e(),_vm._v(" xBlade ")])]),(_vm.heroAmount === 0)?_c('big-button',{staticClass:"button",attrs:{"mainText":'Go to Market',"tagname":"recruit_character"},on:{"click":function($event){return _vm.$router.push({ name: 'market' })}}}):_vm._e(),(_vm.heroAmount > 0)?_c('big-button',{staticClass:"button mint-hero",attrs:{"mainText":"Mint Hero","disabled":!_vm.canRecruit() || _vm.heroAmount < 1,"tagname":"recruit_character"},on:{"click":_vm.onMintCharacter}}):_vm._e(),(_vm.formatSkill() < _vm.recruitCost)?_c('div',[_c('br'),_vm._v(" You can buy more xBlade from "),_c('a',{attrs:{"href":("" + _vm.getExchangeUrl),"target":"_blank"}},[_vm._v("here")]),_vm._v(". Join "),_c('a',{attrs:{"href":"https://t.me/elasticbitcoinxbt","target":"_blank"}},[_vm._v("Telegram community")]),_vm._v(" to get support! ")]):_vm._e()],1):_vm._e(),(_vm.ownCharacters.length > 0)?_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col"},[(_vm.ownCharacters.length > 0)?_c('div',[_c('div',{staticClass:"chara-head-box"},[_c('h1',{staticClass:"chara-title"},[_vm._v("Characters ("+_vm._s(_vm.ownCharacters.length)+")")]),_c('div')]),_c('character-list',{attrs:{"value":_vm.currentCharacterId},on:{"input":_vm.setCurrentCharacter}})],1):_vm._e(),_c('b-modal',{ref:"character-rename-modal",staticClass:"centered-modal",on:{"ok":_vm.renameCharacterCall},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Rename Character ")]},proxy:true}],null,false,3791825810)},[_c('b-form-input',{staticClass:"modal-input",attrs:{"type":"string","placeholder":"New Name"},model:{value:(_vm.characterRename),callback:function ($$v) {_vm.characterRename=$$v},expression:"characterRename"}}),(
            _vm.characterRename !== '' &&
            (_vm.characterRename.length < 2 || _vm.characterRename.length > 24)
          )?_c('span',[_vm._v(" Name must be 2 - 24 characters long. ")]):_vm._e(),(_vm.isRenameProfanish)?_c('span',[_vm._v(" This name contains profanish words and thus will be displayed as follows: "),_c('em',[_vm._v(_vm._s(_vm.cleanRename))])]):_vm._e()],1),_c('b-modal',{ref:"character-change-trait-modal",staticClass:"centered-modal",on:{"ok":_vm.changeCharacterTraitCall},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Change Character's Trait ")]},proxy:true}],null,false,1196498538)},[_c('span',[_vm._v(" Pick a trait to switch to. ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.targetTrait),expression:"targetTrait"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.targetTrait=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.availableTraits),function(x){return _c('option',{key:x,domProps:{"value":x}},[_vm._v(" "+_vm._s(x)+" ")])}),0)])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
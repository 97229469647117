


































































import Vue from 'vue'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { getCleanName } from '../rename-censor'
// import CharacterRoom from "./CharacterRoom.vue";
// import CharacterRoomArt from "./CharacterRoomArt.vue";
import BackgroundItem from './BackgroundItem.vue'
// import CharacterRoomArt from "./CharacterRoomArt.vue";
import { mapCacheActions } from 'vuex-cache'

export default Vue.extend({
  data() {
    return {
      waitingResults: false,
      resultsAvailable: false,
      fightResults: null,
      error: null,
      room: null,
      dataRoom: null,
      playerPower: 0,
      weapon: null,
    }
  },
  components: { BackgroundItem },
  props: [
    'request',
    'handleFight',
    'isMine',
    'isDone',
    'cancelRequestFight',
    'isWin',
    'handleShowWeapon',
  ],

  methods: {
    ...mapCacheActions(['fetchCharacters', 'fetchWeaponId']),
    ...mapActions(['fight', 'getRoom', 'checkPlayerPower']),
    ...mapMutations(['setIsInCombat']),
    getCleanCharacterName(id: number) {
      return getCleanName(this.getCharacterName(id))
    },
    getResult() {
      if (this.isWin) {
        return 'You win this match!'
      } else {
        return 'You lose this match!'
      }
    },
  },
  computed: {
    ...mapState(['characters', 'careerModeRooms']),
    ...mapGetters(['getCharacterName']),
  },

  async mounted() {
    if (this.request.heroId) {
      //@ts-ignore
      await this.fetchCharacters([this.request.heroId])
    }
    //@ts-ignore
    const res: number = await this.checkPlayerPower({
      heroId: this.request.heroId,
      weaponId: this.request.weaponId,
    })
    this.playerPower = res
  },
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app app-v2"},[(_vm.isMaintenance)?_c('router-view'):_vm._e(),(!_vm.isMaintenance)?_c('div',{staticClass:"container-box"},[_c('nav-bar'),_c('div',{staticClass:"content dark-bg-text"},[(_vm.canShowApp)?_c('router-view'):_vm._e()],1),(_vm.showMetamaskWarning && _vm.isMetamask)?_c('div',{staticClass:"fullscreen-warning",attrs:{"sytle":"z-index: 100"}},[_c('div',{staticClass:"starter-panel not-connect"},[_c('span',{staticClass:"starter-panel-heading"},[_vm._v("Metamask Not Detected Or Incorrect Network")]),_c('div',{staticClass:"center row button-div"},[(_vm.showMetamaskWarning)?_c('big-button',{staticClass:"btn btn-pink-bg modal-btn",domProps:{"innerHTML":_vm._s("Add MetaMask")},on:{"click":_vm.startOnboarding}}):_vm._e(),(_vm.showNetworkError)?_c('big-button',{staticClass:"btn btn-pink-bg modal-btn",domProps:{"innerHTML":_vm._s("Switch to BSC Network")},on:{"click":_vm.configureMetaMask}}):_vm._e(),_c('small-button',{staticClass:"btn btn-blue-bg modal-btn",domProps:{"innerHTML":_vm._s('Hide Warning')},on:{"click":_vm.toggleHideWalletWarning}})],1),_c('div',{staticClass:"back-btn",on:{"click":function($event){_vm.isMetamask = false
            _vm.hideWalletWarning = false}}},[_c('i',{staticClass:"fas fa-chevron-left"})])])]):_vm._e(),(
        !_vm.checkIncorectNetwork &&
        !_vm.isMaintenance &&
        !_vm.showMetamaskWarning &&
        _vm.hideWalletWarning &&
        !_vm.canShowApp
      )?_c('div',{staticClass:"fullscreen-warning",attrs:{"sytle":"z-index: 100"}},[_c('div',{staticClass:"starter-panel not-connect switch-network"},[_c('span',{staticClass:"starter-panel-heading"},[_vm._v("Incorrect Network")]),_c('div',{staticClass:"center row button-div"},[_c('big-button',{staticClass:"btn btn-pink-bg modal-btn",domProps:{"innerHTML":_vm._s("Switch to BSC Network")},on:{"click":_vm.configureMetaMask}})],1)])]):_vm._e(),(
        !_vm.hideWalletWarning &&
        (_vm.errorMessage ||
          (_vm.ownCharacters.length === 0 &&
            _vm.skillBalance === '0' &&
            !_vm.hasStakedBalance))
      )?_c('div',{staticClass:"fullscreen-warning"},[_c('div',{staticClass:"starter-panel connect-wallet"},[_c('span',{staticClass:"starter-panel-heading"},[_vm._v(_vm._s(_vm.errorMessage || 'Get Started With CryptoWars'))]),_c('div',{staticClass:"instructions-list"},[_c('p',[_vm._v(" To recruit your first character you need "+_vm._s(_vm.recruitCost)+" xBlade and .001 BNB for gas. You will also need .0015 BNB to do your first few battles, but don't worry, you earn the battle fees back in xBlade rewards immediately! ")]),_vm._m(0)]),_c('button',{staticClass:"hide-modal",on:{"click":_vm.toggleHideWalletWarning}},[_c('img',{attrs:{"src":require("./assets/images/btn-close.svg")}})]),(!_vm.isMetamask)?_c('div',{staticClass:"button-div"},[_c('big-button',{staticClass:"btn btn-pink-bg modal-btn",class:[_vm.isConnecting ? 'disabled' : ''],domProps:{"innerHTML":_vm._s("Connect via Walletconnect")},on:{"click":_vm.connectWalletconnect}}),_c('big-button',{staticClass:"btn btn-pink-bg modal-btn",domProps:{"innerHTML":_vm._s("Connect via metamask")},on:{"click":_vm.checkMetamask}})],1):_vm._e(),(_vm.isMetamask)?_c('div',{staticClass:"button-div"},[_c('big-button',{staticClass:"btn btn-pink-bg modal-btn",domProps:{"innerHTML":_vm._s("Configure MetaMask")},on:{"click":_vm.configureMetaMask}}),_c('big-button',{staticClass:"btn btn-pink-bg modal-btn",class:[_vm.isConnecting ? 'disabled' : ''],domProps:{"innerHTML":_vm._s("Connect to metamask")},on:{"click":_vm.connectMetamask}})],1):_vm._e()])]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Join us on "),_c('a',{attrs:{"href":"https://discord.gg/MQBWnE8t2G","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Discord")]),_vm._v(" / "),_c('a',{attrs:{"href":"https://t.me/elasticbitcoinxbt","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Telegram")]),_vm._v(" / "),_c('a',{attrs:{"href":"https://twitter.com/xbladecryptowar","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Twitter")])])}]

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body main-font market"},[_c('div',{staticClass:"market-hero-weapon",staticStyle:{"max-width":"1769px","margin-left":"auto","margin-right":"auto"}},[_c('button',{class:_vm.activeType === 'character' && 'selected',on:{"click":function($event){_vm.activeType = 'character'
        _vm.isBtnSell = false}}},[_vm._v(" HERO ")]),_c('button',{class:_vm.activeType === 'weapon' && 'selected',on:{"click":function($event){_vm.activeType = 'weapon'
        _vm.isBtnSell = false}}},[_vm._v(" WEAPON ")])]),_c('b-tabs',{staticStyle:{"max-width":"1769px","margin-left":"auto","margin-right":"auto"},attrs:{"justified":""}},[_c('b-tab',{on:{"click":function($event){_vm.clearData()
        _vm.browseTabActive = true
        _vm.skillShopTabActive = false
        _vm.searchAllCharacterListings(_vm.currentPage - 1)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" ON SALES NFTS "),_c('hint',{staticClass:"hint",attrs:{"text":"NFT stands for Non Fungible Token.<br>Weapons and Characters are NFTs of the ERC721 standard"}})]},proxy:true}])},[_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"search-results"},[_c('b-pagination',{directives:[{name:"visible",rawName:"v-visible",value:(_vm.resultSearch.length > 0),expression:"resultSearch.length > 0"}],staticClass:"customPagination",attrs:{"align":"center","total-rows":_vm.totalPages,"per-page":_vm.activeType === 'weapon' ? _vm.weaponShowLimit : _vm.characterShowLimit,"first-number":"","last-number":""},nativeOn:{"click":function($event){;(_vm.activeType == 'weapon' &&
                  _vm.searchAllWeaponListings(_vm.currentPage - 1)) ||
                  (_vm.activeType == 'character' &&
                    _vm.searchAllCharacterListings(_vm.currentPage - 1)) ||
                  (_vm.activeType == 'shield' &&
                    _vm.searchAllShieldListings(_vm.currentPage - 1))}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),(_vm.activeType === 'character')?_c('character-list',{attrs:{"showFilters":true,"showGivenCharacterIds":true,"characterIds":_vm.resultSearch,"showLimit":_vm.characterShowLimit,"isMarket":true,"isPage":true},on:{"character-filters-changed":function($event){return _vm.searchAllCharacterListings(0)}},scopedSlots:_vm._u([{key:"above",fn:function(ref){
                    var id = ref.character.id;
return [_c('div',[(_vm.nftPricesById[id])?_c('span',{staticClass:"d-block text-center"},[(_vm.convertWeiToSkill(_vm.nftPricesById[id]) !== '0')?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                        content: _vm.maxPrecisionSkill(_vm.nftPricesById[id]),
                        trigger: _vm.isMobile() ? 'click' : 'hover',
                      }),expression:"{\n                        content: maxPrecisionSkill(nftPricesById[id]),\n                        trigger: isMobile() ? 'click' : 'hover',\n                      }",modifiers:{"top":true}}],staticClass:"value-price",on:{"mouseover":function($event){_vm.hover = !_vm.isMobile() || true},"mouseleave":function($event){_vm.hover = !_vm.isMobile()}}},[_vm._v(" Price "),_c('span',[_vm._v(_vm._s(_vm._f("dynamicDecimals")(_vm.convertWeiToSkill(_vm.nftPricesById[id]),2, 4)))]),_vm._v(" xBlade ")]):_vm._e()]):_c('span',{staticClass:"d-block text-center"},[_vm._v("Loading price...")]),_c('button',{staticClass:"gtag-link-others btn-purchase",class:[!_vm.canPurchase ? 'disabled-button' : ''],attrs:{"hidden":_vm.convertWeiToSkill(_vm.nftPricesById[id]) === '0',"variant":"primary","tagname":"confirm_purchase"},on:{"click":function($event){_vm.selectedNftId = id
                      _vm.canPurchase && _vm.purchaseNft()}}},[_vm._v(" "+_vm._s(_vm.convertWeiToSkill(_vm.nftPricesById[id]) !== '0' ? 'PURCHASE' : 'SOLD')+" "),(!_vm.canPurchase)?_c('b-icon-question-circle',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(
                        'You already have max amount of characters.'
                      ),expression:"\n                        'You already have max amount of characters.'\n                      ",modifiers:{"bottom":true}}]}):_vm._e()],1)])]}},{key:"sold",fn:function(ref){
                      var id = ref.character.id;
return [(
                    _vm.nftPricesById[id] &&
                    _vm.convertWeiToSkill(_vm.nftPricesById[id]) === '0'
                  )?_c('div',{staticClass:"sold"},[_c('span',[_vm._v("sold")])]):_vm._e()]}}],null,false,485945837),model:{value:(_vm.selectedNftId),callback:function ($$v) {_vm.selectedNftId=$$v},expression:"selectedNftId"}}):_vm._e(),(_vm.activeType === 'weapon')?_c('weapon-grid',{attrs:{"showGivenWeaponIds":true,"weaponIds":_vm.resultSearch,"showLimit":_vm.weaponShowLimit,"showReforgedToggle":false,"showFavoriteToggle":false,"canFavorite":false,"isMarket":true,"isPage":true,"showFilters":""},on:{"weapon-filters-changed":function($event){return _vm.searchAllWeaponListings(0)}},scopedSlots:_vm._u([{key:"above",fn:function(ref){
                  var id = ref.weapon.id;
return [_c('div',[(_vm.nftPricesById[id])?_c('span',{staticClass:"d-block text-center"},[(_vm.convertWeiToSkill(_vm.nftPricesById[id]) !== '0')?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                        content: _vm.maxPrecisionSkill(_vm.nftPricesById[id]),
                        trigger: _vm.isMobile() ? 'click' : 'hover',
                      }),expression:"{\n                        content: maxPrecisionSkill(nftPricesById[id]),\n                        trigger: isMobile() ? 'click' : 'hover',\n                      }",modifiers:{"top":true}}],staticClass:"value-price",on:{"mouseover":function($event){_vm.hover = !_vm.isMobile() || true},"mouseleave":function($event){_vm.hover = !_vm.isMobile()}}},[_vm._v(" Price "),_c('span',[_vm._v(_vm._s(_vm._f("dynamicDecimals")(_vm.convertWeiToSkill(_vm.nftPricesById[id]),2, 4)))]),_vm._v(" xBlade ")]):_vm._e()]):_c('span',{staticClass:"d-block text-center"},[_vm._v("Loading price...")]),_c('button',{staticClass:"gtag-link-others btn-purchase",attrs:{"hidden":_vm.convertWeiToSkill(_vm.nftPricesById[id]) === '0',"variant":"primary"},on:{"click":function($event){_vm.selectedNftId = id
                      _vm.purchaseNft()}}},[_vm._v(" "+_vm._s(_vm.convertWeiToSkill(_vm.nftPricesById[id]) !== '0' ? 'PURCHASE' : 'SOLD')+" ")])])]}},{key:"sold",fn:function(ref){
                      var id = ref.weapon.id;
return [(
                    _vm.nftPricesById[id] &&
                    _vm.convertWeiToSkill(_vm.nftPricesById[id]) === '0'
                  )?_c('div',{staticClass:"sold"},[_c('span',[_vm._v("sold")])]):_vm._e()]}}],null,false,2632398653),model:{value:(_vm.selectedNftId),callback:function ($$v) {_vm.selectedNftId=$$v},expression:"selectedNftId"}}):_vm._e(),_c('b-pagination',{directives:[{name:"visible",rawName:"v-visible",value:(_vm.resultSearch.length > 0),expression:"resultSearch.length > 0"}],staticClass:"customPagination",staticStyle:{"margin-top":"20px"},attrs:{"align":"center","total-rows":_vm.totalPages,"per-page":_vm.activeType === 'weapon' ? _vm.weaponShowLimit : _vm.characterShowLimit,"first-number":"","last-number":""},nativeOn:{"click":function($event){;(_vm.activeType == 'weapon' &&
                  _vm.searchAllWeaponListings(_vm.currentPage - 1)) ||
                  (_vm.activeType == 'character' &&
                    _vm.searchAllCharacterListings(_vm.currentPage - 1)) ||
                  (_vm.activeType == 'shield' &&
                    _vm.searchAllShieldListings(_vm.currentPage - 1))}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])]),(_vm.waitingMarketOutcome)?_c('div',{staticClass:"modal-loading"},[_c('div',{staticClass:"modal-loading-content"},[_c('div',{staticClass:"outcome"},[_c('i',{staticClass:"fas fa-spinner fa-spin"}),_vm._v(" Loading... ")])])]):_vm._e()]),_c('b-tab',{on:{"click":function($event){_vm.clearData()
        _vm.loadMarketTaxes()
        _vm.browseTabActive = false
        _vm.skillShopTabActive = false
        _vm.isSell = true}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" MY NFTS "),_c('hint',{staticClass:"hint",attrs:{"text":"When you list an NFT for sale, it is transferred to the<br />\n          market until someone buys it or you cancel the sale"}})]},proxy:true}])},[_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12 col-xl-3 col-md-12 col-sm-12"},[_c('div',{staticClass:"row button-row"},[_c('div',{staticClass:"mb-2"},[_c('b-button',{staticClass:"gtag-link-others search-btn",class:_vm.isBtnSell === false && _vm.activeType === 'weapon'
                    ? 'gtag-link-others search-btn selected'
                    : 'gtag-link-others search-btn',attrs:{"tagname":"show_characters_market"},on:{"click":function($event){;(_vm.activeType = 'weapon'), (_vm.isBtnSell = false)}}},[_vm._v("Show Weapons")])],1),_c('div',{staticClass:"mb-2"},[_c('b-button',{class:_vm.isBtnSell === false && _vm.activeType === 'character'
                    ? 'gtag-link-others search-btn selected'
                    : 'gtag-link-others search-btn',attrs:{"tagname":"show_weapons_market"},on:{"click":function($event){;(_vm.activeType = 'character'), (_vm.isBtnSell = false)}}},[_vm._v("Show Characters")])],1),_c('div',[_c('b-modal',{ref:"listing-setup-modal",staticClass:"centered-modal",on:{"ok":function($event){!_vm.priceChangeModal
                    ? _vm.addListingForNft()
                    : _vm.updateNftListingPrice()}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(!_vm.priceChangeModal ? ("Sell " + _vm.activeType) : ("Change " + _vm.activeType + " price"))+" ")]},proxy:true}])},[_c('b-form-input',{staticClass:"modal-input",attrs:{"type":"number","max":10000,"placeholder":"Sell Price (xBlade)"},model:{value:(_vm.listingSellPrice),callback:function ($$v) {_vm.listingSellPrice=$$v},expression:"listingSellPrice"}}),(_vm.listingSellPrice)?_c('span',[_vm._v("Do you want to sell your "+_vm._s(_vm.activeType)+" for "+_vm._s(_vm.listingSellPrice)+" xBlade?"),_c('br'),_c('i',[_vm._v("The buyer will pay an extra "+_vm._s(_vm.activeListingMarketTax())+"% market fee for a total of "+_vm._s(_vm.calculatedBuyerCost(_vm.listingSellPrice))+" xBlade")])]):_vm._e()],1)],1),_c('div',{staticClass:"mb-2"},[_c('b-button',{class:_vm.isBtnSell === true && _vm.activeType === 'weapon'
                    ? 'gtag-link-others search-btn selected'
                    : 'gtag-link-others search-btn',attrs:{"tagname":"search_own_weapons"},on:{"click":function($event){_vm.searchOwnListings('weapon'), (_vm.isBtnSell = true)}}},[_vm._v("Selling Weapons")])],1),_c('div',{staticClass:"mb-2"},[_c('b-button',{class:_vm.isBtnSell === true && _vm.activeType === 'character'
                    ? 'gtag-link-others search-btn selected'
                    : 'gtag-link-others search-btn',attrs:{"tagname":"search_own_characters"},on:{"click":function($event){_vm.searchOwnListings('character'), (_vm.isBtnSell = true)}}},[_vm._v("Selling Characters")])],1)])]),_c('div',{staticClass:"search-results"},[_c('weapon-grid',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeType === 'weapon' && _vm.isBtnSell),expression:"activeType === 'weapon' && isBtnSell"}],attrs:{"showGivenWeaponIds":true,"showReforgedToggle":false,"showFavoriteToggle":false,"canFavorite":false,"weaponIds":_vm.searchResults,"isMarket":true,"cancelNftListing":_vm.cancelNftListing,"isBtnSell":_vm.isBtnSell,"showListingSetupModal":_vm.showListingSetupModal},scopedSlots:_vm._u([{key:"above",fn:function(ref){
                    var id = ref.weapon.id;
return [_c('div',[(_vm.nftPricesById[id])?_c('span',{staticClass:"d-block text-center"},[(_vm.convertWeiToSkill(_vm.nftPricesById[id]) !== '0')?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                      content: _vm.maxPrecisionSkill(_vm.nftPricesById[id]),
                      trigger: _vm.isMobile() ? 'click' : 'hover',
                    }),expression:"{\n                      content: maxPrecisionSkill(nftPricesById[id]),\n                      trigger: isMobile() ? 'click' : 'hover',\n                    }",modifiers:{"top":true}}],staticClass:"value-price",on:{"mouseover":function($event){_vm.hover = !_vm.isMobile() || true},"mouseleave":function($event){_vm.hover = !_vm.isMobile()}}},[_vm._v(" Price "),_c('span',[_vm._v(_vm._s(_vm._f("dynamicDecimals")(_vm.convertWeiToSkill(_vm.nftPricesById[id]),2, 4)))]),_vm._v(" xBlade ")]):_vm._e()]):_c('span',{staticClass:"d-block text-center"},[_vm._v("Loading price...")])])]}},{key:"sold",fn:function(ref){
                    var id = ref.weapon.id;
return [(
                  _vm.nftPricesById[id] &&
                  _vm.convertWeiToSkill(_vm.nftPricesById[id]) === '0'
                )?_c('div',{staticClass:"sold"},[_c('span',[_vm._v("sold")])]):_vm._e()]}}]),model:{value:(_vm.selectedNftId),callback:function ($$v) {_vm.selectedNftId=$$v},expression:"selectedNftId"}}),_c('character-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeType === 'character' && _vm.isBtnSell),expression:"activeType === 'character' && isBtnSell"}],attrs:{"showGivenCharacterIds":true,"characterIds":_vm.searchResults,"isMarket":true,"cancelNftListing":_vm.cancelNftListing,"isBtnSell":_vm.isBtnSell,"showListingSetupModal":_vm.showListingSetupModal},scopedSlots:_vm._u([{key:"above",fn:function(ref){
                var id = ref.character.id;
return [_c('div',[(_vm.nftPricesById[id])?_c('span',{staticClass:"d-block text-center"},[(_vm.convertWeiToSkill(_vm.nftPricesById[id]) !== '0')?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                      content: _vm.maxPrecisionSkill(_vm.nftPricesById[id]),
                      trigger: _vm.isMobile() ? 'click' : 'hover',
                    }),expression:"{\n                      content: maxPrecisionSkill(nftPricesById[id]),\n                      trigger: isMobile() ? 'click' : 'hover',\n                    }",modifiers:{"top":true}}],staticClass:"value-price",on:{"mouseover":function($event){_vm.hover = !_vm.isMobile() || true},"mouseleave":function($event){_vm.hover = !_vm.isMobile()}}},[_vm._v(" Price "),_c('span',[_vm._v(_vm._s(_vm._f("dynamicDecimals")(_vm.convertWeiToSkill(_vm.nftPricesById[id]),2, 4)))]),_vm._v(" xBlade ")]):_vm._e()]):_c('span',{staticClass:"d-block text-center"},[_vm._v("Loading price...")])])]}},{key:"sold",fn:function(ref){
                    var id = ref.character.id;
return [(
                  _vm.nftPricesById[id] &&
                  _vm.convertWeiToSkill(_vm.nftPricesById[id]) === '0'
                )?_c('div',{staticClass:"sold"},[_c('span',[_vm._v("sold")])]):_vm._e()]}}]),model:{value:(_vm.selectedNftId),callback:function ($$v) {_vm.selectedNftId=$$v},expression:"selectedNftId"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeType === 'weapon' && !_vm.isBtnSell),expression:"activeType === 'weapon' && !isBtnSell"}],staticClass:"col-12 col-xl-9 col-md-12 col-sm-12 sell-grid"},[_c('weapon-grid',{attrs:{"showReforgedWeaponsDefVal":true,"showFavoriteWeaponsDefVal":true,"canFavorite":false,"checkBar":false,"isSell":_vm.isSell,"sellClick":_vm.showListingSetupModal,"checklist":false,"isMarket":true},model:{value:(_vm.selectedNftId),callback:function ($$v) {_vm.selectedNftId=$$v},expression:"selectedNftId"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeType === 'character' && !_vm.isBtnSell),expression:"activeType === 'character' && !isBtnSell"}],staticClass:"col-12 col-xl-9 col-md-12 col-sm-12 sell-grid"},[_c('character-list',{attrs:{"sellClick":_vm.showListingSetupModal,"isSell":_vm.isSell,"checklist":false,"isMarket":true},model:{value:(_vm.selectedNftId),callback:function ($$v) {_vm.selectedNftId=$$v},expression:"selectedNftId"}})],1),(_vm.activeType === 'shield')?_c('div',{staticClass:"col-12col-xl-3 col-md-12 col-sm-12 sell-grid"},[_c('nft-list',{attrs:{"isShop":false,"canFavorite":false},model:{value:(_vm.selectedNftId),callback:function ($$v) {_vm.selectedNftId=$$v},expression:"selectedNftId"}})],1):_vm._e()]),(_vm.waitingMarketOutcome)?_c('div',{staticClass:"modal-loading"},[_c('div',{staticClass:"modal-loading-content"},[_c('div',{staticClass:"outcome"},[_c('i',{staticClass:"fas fa-spinner fa-spin"}),_vm._v(" Loading... ")])])]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
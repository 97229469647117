
























































































import Vue from 'vue'

import ViewLinks from './ViewLinks.vue'
import Options from './Options.vue'
import SkillBalanceDisplay from './smart/SkillBalanceDisplay.vue'
// import ClaimRewards from "./smart/ClaimRewards.vue";
// import OptionsMobile from "./OptionsMobile.vue";

import Events from '../events'
import { mapGetters, mapMutations } from 'vuex'

export default Vue.extend({
  components: {
    ViewLinks,
    SkillBalanceDisplay,
    // ClaimRewards,
    Options,
    // OptionsMobile,
  },

  data() {
    return {
      checkCloseOption: '',
      // canShowRewardsBar: true,
    }
  },

  computed: {
    ...mapGetters([
      'getIsCharacterViewExpanded',
      'ownCharacters',
      'defaultAccount',
    ]),
    referralAddress(): string {
      // @ts-ignore
      const referralAddress = this.$route.query.r
      if (referralAddress && referralAddress !== this.defaultAccount) {
        return referralAddress
      }
      return ''
    },
  },

  methods: {
    ...mapMutations(['setIsCharacterViewExpanded']),
    // checkStorage(): void {
    //   this.canShowRewardsBar = localStorage.getItem("hideRewards") === "false";
    // },
    toggleCharacterView(): void {
      this.setIsCharacterViewExpanded(!this.getIsCharacterViewExpanded)
      localStorage.setItem(
        'isCharacterViewExpanded',
        this.getIsCharacterViewExpanded ? 'true' : 'false'
      )
    },
  },

  updated() {
    this.checkCloseOption = ''
  },

  mounted() {
    Events.$on('hide-option', (i: any) => {
      this.checkCloseOption = i
    })
    // this.checkStorage();
    // Events.$on("setting:hideRewards", () => this.checkStorage());
  },
})
